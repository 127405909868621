import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css"; // CSS customizado para centralizar a tela de login
import logo from "../assets/logo.png"; // Logo da empresa

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://podologia-brotas-backend.onrender.com/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Salvando o token no localStorage
        localStorage.setItem("token", data.token);
        // Redirecionando para a página de pacientes
        navigate("/pacientes");
      } else {
        alert("Erro ao fazer login: " + data.message);
      }
    } catch (error) {
      console.error("Erro ao fazer login:", error);
    }
  };

  return (
    <div className="login-container d-flex justify-content-center align-items-center vh-100">
      <div
        className="card shadow p-4"
        style={{ maxWidth: "400px", width: "100%" }}
      >
        {/* Logo da empresa */}
        <div className="text-center">
          <img
            src={logo}
            alt="Podologia Brotas - Maria Olivia"
            className="img-fluid logo"
          />
        </div>
        <form onSubmit={handleLogin}>
          <div className="mb-3">
            <label className="form-label">Usuário</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Senha</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">
            Entrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
