import React from "react";
import { Navigate } from "react-router-dom";

// Verifica se o usuário está autenticado
const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  // Permitir acesso se estiver rodando localmente (http://localhost)
  const isLocalhost = window.location.hostname === "localhost";

  return isLocalhost || token !== null;
};

// Componente de rota privada
const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/" />;
};

export default PrivateRoute;
