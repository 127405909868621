import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Container, Button } from "react-bootstrap"; // Importando Bootstrap

const PatientList = () => {
  const [patients, setPatients] = useState([]);
  const [error, setError] = useState("");

  // Função para buscar pacientes da API
  const fetchPatients = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        "https://podologia-brotas-backend.onrender.com/api/patients",
        config
      );
      setPatients(response.data);
    } catch (error) {
      console.error("Erro ao buscar pacientes:", error);
      if (error.response && error.response.status === 401) {
        setError("Não autorizado. Faça login para acessar esta página.");
      } else {
        setError("Ocorreu um erro ao buscar os pacientes.");
      }
    }
  };

  // Chamada da função ao carregar o componente
  useEffect(() => {
    fetchPatients();
  }, []);

  // Função para tratar a edição de um paciente
  const handleEdit = (paciente_cpf) => {
    // Redireciona para a página de edição do paciente (ajuste a rota conforme necessário)
    window.location.href = `/anamnese/${paciente_cpf}`;
  };

  return (
    <Container>
      <h2 className="my-4">Listagem de Pacientes</h2>
      {error && <p className="text-danger">{error}</p>}
      <Table
        bordered
        hover
        responsive
        className="table-striped table-hover modern-table"
      >
        <thead>
          <tr>
            <th>CPF</th>
            <th>Nome</th>
            <th>Endereço</th>
            <th>Bairro</th>
            <th>Cidade</th>
            <th>Estado</th>
            <th>Telefone</th>
            <th>Telefone WhatsApp</th>
            <th>Data de Nascimento</th>
            <th>Etnia</th>
            <th>Sexo</th>
            <th>Profissão</th>
            <th>Ações</th> {/* Nova coluna para o botão "Editar" */}
          </tr>
        </thead>
        <tbody>
          {patients.length > 0 ? (
            patients.map((patient, index) => (
              <tr
                key={patient.paciente_cpf}
                style={{
                  backgroundColor: index % 2 === 0 ? "#B0988E" : "#FFFFFF",
                }}
              >
                <td>{patient.paciente_cpf}</td>
                <td>{patient.nome}</td>
                <td>{patient.endereco}</td>
                <td>{patient.bairro}</td>
                <td>{patient.cidade}</td>
                <td>{patient.estado}</td>
                <td>{patient.telefone}</td>
                <td>{patient.telefone_whatsapp}</td>
                <td>
                  {new Date(patient.data_nascimento).toLocaleDateString()}
                </td>
                <td>{patient.etnia}</td>
                <td>{patient.sexo}</td>
                <td>{patient.profissao}</td>
                <td>
                  {/* Botão Editar */}
                  <Button
                    variant="primary"
                    onClick={() => handleEdit(patient.paciente_cpf)}
                  >
                    Editar
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="13" className="text-center">
                Nenhum paciente encontrado.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default PatientList;
