import React from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Substituindo useHistory por useNavigate
import logo from "../assets/logo.png"; // Caminho para o seu logo

const Sidebar = () => {
  const navigate = useNavigate(); // Hook para navegação

  // Função para deslogar
  const handleLogout = () => {
    localStorage.removeItem("token"); // Limpa o token de autenticação
    navigate("/"); // Redireciona para a página de login
  };

  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{ height: "100vh", backgroundColor: "#f8f9fa" }}
    >
      {/* Logo no topo */}
      <div className="p-4">
        <img
          src={logo}
          alt="Logo"
          style={{ width: "100%", maxWidth: "150px" }}
        />
      </div>

      {/* Navegação no meio */}
      <Nav className="flex-column p-4">
        <Nav.Link href="/pacientes">Pacientes</Nav.Link>
        <Nav.Link href="/anamnese">Anamnese</Nav.Link>
      </Nav>

      {/* Link de deslogar no final */}
      <div className="p-4">
        <button className="btn btn-danger btn-block" onClick={handleLogout}>
          Deslogar
        </button>
      </div>
    </div>
  );
};
export default Sidebar;
