import React, { useState, useEffect } from "react";
import { Tab, Tabs, Form, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom"; // Para pegar o CPF da URL e redirecionar
import "./Anamnese.css";

const Anamnese = () => {
  const [key, setKey] = useState("dadosPaciente");
  const { cpf } = useParams(); // Obtém o CPF da URL (se existir)
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    paciente_cpf: "",
    nome: "",
    endereco: "",
    bairro: "",
    cidade: "",
    estado: "",
    telefone: "",
    telefone_whatsapp: "",
    data_nascimento: "",
    etnia: "",
    sexo: "",
    profissao: "",
    pratica_esporte: false,
    frequencia: "",
    numero_calcado: "",
    tipo_calcado: "",
    cirurgia: false,
    medicamento: false,
    alergias: "",
    patologia: "",
    diabetes: false,
    familiares_diabeticos: false,
    etilismo: false,
    cardiopatias: false,
    tabagismo: false,
    dst: false,
    pressao: false,
    gravidez: false,
    motivo_consulta: "",
    formato_unha_pe_direito: "",
    alteracao_unhas_pe_direito: [],
    alteracao_pele_pe_direito: [],
    alteracao_osso_pe_direito: [],
    formato_unha_pe_esquerdo: "",
    alteracao_unhas_pe_esquerdo: [],
    alteracao_pele_pe_esquerdo: [],
    alteracao_osso_pe_esquerdo: [],
  });

  // Função para buscar os dados da anamnese para edição
  const fetchAnamnese = async () => {
    if (cpf) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://podologia-brotas-backend.onrender.com/api/anamnese/${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFormData(response.data); // Preenche todos os dados da anamnese no formulário
        setIsEditing(true); // Modo de edição
      } catch (err) {
        console.error("Erro ao buscar anamnese", err);
      }
    }
  };

  useEffect(() => {
    if (cpf) {
      fetchAnamnese(); // Busca os dados se estamos editando
    }
  }, [cpf]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleCheckboxGroupChange = (groupName, value) => {
    setFormData((prevData) => {
      const currentValues = prevData[groupName];
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];
      return {
        ...prevData,
        [groupName]: newValues,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      let response;
      if (isEditing) {
        // Atualiza a anamnese existente (PUT)
        response = await axios.put(
          `https://podologia-brotas-backend.onrender.com/api/anamnese/${cpf}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert("Anamnese atualizada com sucesso.");
      } else {
        // Cria uma nova anamnese (POST)
        response = await axios.post(
          "https://podologia-brotas-backend.onrender.com/api/anamnese",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert("Anamnese criada com sucesso.");
      }
      navigate("/pacientes"); // Redireciona após salvar
    } catch (err) {
      alert(err.response?.data?.message || "Erro ao salvar anamnese.");
    }
  };

  return (
    <div className="container mt-5">
      <h2>Anamnese do Paciente</h2>
      <Tabs
        id="anamnese-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        {/* Aba 1: Dados do Paciente */}
        <Tab eventKey="dadosPaciente" title="Dados do Paciente">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formCpf">
                  <Form.Label>CPF</Form.Label>
                  <Form.Control
                    type="text"
                    name="paciente_cpf"
                    value={formData.paciente_cpf}
                    onChange={handleChange}
                    placeholder="CPF do Paciente"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formNome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    placeholder="Nome do Paciente"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formEndereco">
                  <Form.Label>Endereço</Form.Label>
                  <Form.Control
                    type="text"
                    name="endereco"
                    value={formData.endereco}
                    onChange={handleChange}
                    placeholder="Endereço"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formBairro">
                  <Form.Label>Bairro</Form.Label>
                  <Form.Control
                    type="text"
                    name="bairro"
                    value={formData.bairro}
                    onChange={handleChange}
                    placeholder="Bairro"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <Form.Group controlId="formCidade">
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    type="text"
                    name="cidade"
                    value={formData.cidade}
                    onChange={handleChange}
                    placeholder="Cidade"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formEstado">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    type="text"
                    name="estado"
                    value={formData.estado}
                    onChange={handleChange}
                    placeholder="Estado"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formTelefone">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    type="text"
                    name="telefone"
                    value={formData.telefone}
                    onChange={handleChange}
                    placeholder="Telefone"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formWhatsapp">
                  <Form.Label>Telefone WhatsApp</Form.Label>
                  <Form.Control
                    type="text"
                    name="telefone_whatsapp"
                    value={formData.telefone_whatsapp}
                    onChange={handleChange}
                    placeholder="Telefone WhatsApp"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formDataNascimento">
                  <Form.Label>Data de Nascimento</Form.Label>
                  <Form.Control
                    type="date"
                    name="data_nascimento"
                    value={formData.data_nascimento}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <Form.Group controlId="formEtnia">
                  <Form.Label>Etnia</Form.Label>
                  <Form.Control
                    type="text"
                    name="etnia"
                    value={formData.etnia}
                    onChange={handleChange}
                    placeholder="Etnia"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formSexo">
                  <Form.Label>Sexo</Form.Label>
                  <Form.Select
                    name="sexo"
                    value={formData.sexo}
                    onChange={handleChange}
                  >
                    <option value="">Selecione...</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                    <option value="Outro">Outro</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formProfissao">
                  <Form.Label>Profissão</Form.Label>
                  <Form.Control
                    type="text"
                    name="profissao"
                    value={formData.profissao}
                    onChange={handleChange}
                    placeholder="Profissão"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formPraticaEsporte">
                  <Form.Label>Pratica Esporte?</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Sim"
                    name="pratica_esporte"
                    value="true"
                    checked={formData.pratica_esporte === true}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        pratica_esporte: e.target.value === "true",
                      })
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="Não"
                    name="pratica_esporte"
                    value="false"
                    checked={formData.pratica_esporte === false}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        pratica_esporte: e.target.value === "false",
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formFrequencia">
                  <Form.Label>Frequência</Form.Label>
                  <Form.Control
                    type="text"
                    name="frequencia"
                    value={formData.frequencia}
                    onChange={handleChange}
                    placeholder="Frequência"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <Form.Group controlId="formNumeroCalcado">
                  <Form.Label>Número do Calçado</Form.Label>
                  <Form.Control
                    type="number"
                    name="numero_calcado"
                    value={formData.numero_calcado}
                    onChange={handleChange}
                    placeholder="Número do Calçado"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formTipoCalcado">
                  <Form.Label>Tipo de Calçado Mais Usado</Form.Label>
                  <Form.Control
                    type="text"
                    name="tipo_calcado"
                    value={formData.tipo_calcado}
                    onChange={handleChange}
                    placeholder="Tipo de Calçado"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formCirurgia">
                  <Form.Label>Fez Cirurgia em Membros Inferiores?</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Sim"
                    name="cirurgia"
                    value="true"
                    checked={formData.cirurgia === true}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        cirurgia: e.target.value === "true",
                      })
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="Não"
                    name="cirurgia"
                    value="false"
                    checked={formData.cirurgia === false}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        cirurgia: e.target.value === "false",
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Continue com os outros campos... */}

            <Button variant="primary" type="submit">
              Salvar Anamnese
            </Button>
          </Form>
        </Tab>

        {/* Aba 2: Pé Direito */}
        {isEditing && (
          <Tab eventKey="peDireito" title="Pé Direito">
            <Form>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group controlId="formFormatoUnhasPeDireito">
                    <Form.Label>Formato das Unhas (Pé Direito)</Form.Label>
                    <Form.Select
                      name="formato_unha_pe_direito"
                      value={formData.formato_unha_pe_direito}
                      onChange={handleChange}
                    >
                      <option value="">Selecione...</option>
                      <option value="Involuta">Involuta</option>
                      <option value="Telha">Telha</option>
                      <option value="Funil">Funil</option>
                      <option value="Gancho">Gancho</option>
                      <option value="Caracol">Caracol</option>
                      <option value="Torques">Torques</option>
                      <option value="Normal">Normal</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              {/* Alteração e lesões nas unhas (Pé Direito) */}
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group controlId="formAlteracaoUnhasPeDireito">
                    <Form.Label>
                      Alteração e Lesões nas Unhas (Pé Direito)
                    </Form.Label>
                    <div className="d-flex flex-wrap">
                      {[
                        "Onicoatrofilia",
                        "Onicoceiptose",
                        "Onicorreze",
                        "Granuloma",
                        "Onicogrifose",
                        "Alteração da Cor",
                        "Onicolise",
                        "Onicofose",
                        "Onicornicose",
                        "Extose Ungueal",
                        "Psoríase Ungueal",
                      ].map((item, index) => (
                        <Form.Check
                          inline
                          key={index}
                          type="checkbox"
                          label={item}
                          name="alteracao_unhas_pe_direito"
                          checked={formData.alteracao_unhas_pe_direito.includes(
                            item
                          )}
                          onChange={() =>
                            handleCheckboxGroupChange(
                              "alteracao_unhas_pe_direito",
                              item
                            )
                          }
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Alteração e lesões na pele (Pé Direito) */}
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group controlId="formAlteracaoPelePeDireito">
                    <Form.Label>
                      Alteração e Lesões na Pele (Pé Direito)
                    </Form.Label>
                    <div className="d-flex flex-wrap">
                      {[
                        "Bromidose",
                        "Neuropática",
                        "Androse",
                        "Fissuras",
                        "Hiperdrose",
                        "Disidrose",
                        "Isquêmica",
                        "Mal Perfurante",
                        "Frieira",
                        "Psoríase",
                        "Tinea Pedis",
                      ].map((item, index) => (
                        <Form.Check
                          inline
                          key={index}
                          type="checkbox"
                          label={item}
                          name="alteracao_pele_pe_direito"
                          checked={formData.alteracao_pele_pe_direito.includes(
                            item
                          )}
                          onChange={() =>
                            handleCheckboxGroupChange(
                              "alteracao_pele_pe_direito",
                              item
                            )
                          }
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Alteração e lesões ósseas (Pé Direito) */}
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group controlId="formAlteracaoOssoPeDireito">
                    <Form.Label>
                      Alteração e Lesões Ósseas (Pé Direito)
                    </Form.Label>
                    <div className="d-flex flex-wrap">
                      {[
                        "Hálux Valgus",
                        "Hálux Rígido",
                        "Esporão de Calcâneo",
                        "Pé Cavo",
                        "Pé Plano",
                        "Dedos em Garra/Martelo",
                        "Perodactilia",
                      ].map((item, index) => (
                        <Form.Check
                          inline
                          key={index}
                          type="checkbox"
                          label={item}
                          name="alteracao_osso_pe_direito"
                          checked={formData.alteracao_osso_pe_direito.includes(
                            item
                          )}
                          onChange={() =>
                            handleCheckboxGroupChange(
                              "alteracao_osso_pe_direito",
                              item
                            )
                          }
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Button variant="primary" onClick={handleSubmit}>
                Atualizar Anamnese
              </Button>
            </Form>
          </Tab>
        )}

        {/* Aba 3: Pé Esquerdo */}
        {isEditing && (
          <Tab eventKey="peEsquerdo" title="Pé Esquerdo">
            <Form>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group controlId="formFormatoUnhasPeEsquerdo">
                    <Form.Label>Formato das Unhas (Pé Esquerdo)</Form.Label>
                    <Form.Select
                      name="formato_unha_pe_esquerdo"
                      value={formData.formato_unha_pe_esquerdo}
                      onChange={handleChange}
                    >
                      <option value="">Selecione...</option>
                      <option value="Involuta">Involuta</option>
                      <option value="Telha">Telha</option>
                      <option value="Funil">Funil</option>
                      <option value="Gancho">Gancho</option>
                      <option value="Caracol">Caracol</option>
                      <option value="Torques">Torques</option>
                      <option value="Normal">Normal</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              {/* Alteração e lesões nas unhas (Pé Esquerdo) */}
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group controlId="formAlteracaoUnhasPeEsquerdo">
                    <Form.Label>
                      Alteração e Lesões nas Unhas (Pé Esquerdo)
                    </Form.Label>
                    <div className="d-flex flex-wrap">
                      {[
                        "Onicoatrofilia",
                        "Onicoceiptose",
                        "Onicorreze",
                        "Granuloma",
                        "Onicogrifose",
                        "Alteração da Cor",
                        "Onicolise",
                        "Onicofose",
                        "Onicornicose",
                        "Extose Ungueal",
                        "Psoríase Ungueal",
                      ].map((item, index) => (
                        <Form.Check
                          inline
                          key={index}
                          type="checkbox"
                          label={item}
                          name="alteracao_unhas_pe_esquerdo"
                          checked={formData.alteracao_unhas_pe_esquerdo.includes(
                            item
                          )}
                          onChange={() =>
                            handleCheckboxGroupChange(
                              "alteracao_unhas_pe_esquerdo",
                              item
                            )
                          }
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Alteração e lesões na pele (Pé Esquerdo) */}
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group controlId="formAlteracaoPelePeEsquerdo">
                    <Form.Label>
                      Alteração e Lesões na Pele (Pé Esquerdo)
                    </Form.Label>
                    <div className="d-flex flex-wrap">
                      {[
                        "Bromidose",
                        "Neuropática",
                        "Androse",
                        "Fissuras",
                        "Hiperdrose",
                        "Disidrose",
                        "Isquêmica",
                        "Mal Perfurante",
                        "Frieira",
                        "Psoríase",
                        "Tinea Pedis",
                      ].map((item, index) => (
                        <Form.Check
                          inline
                          key={index}
                          type="checkbox"
                          label={item}
                          name="alteracao_pele_pe_esquerdo"
                          checked={formData.alteracao_pele_pe_esquerdo.includes(
                            item
                          )}
                          onChange={() =>
                            handleCheckboxGroupChange(
                              "alteracao_pele_pe_esquerdo",
                              item
                            )
                          }
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Alteração e lesões ósseas (Pé Esquerdo) */}
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group controlId="formAlteracaoOssoPeEsquerdo">
                    <Form.Label>
                      Alteração e Lesões Ósseas (Pé Esquerdo)
                    </Form.Label>
                    <div className="d-flex flex-wrap">
                      {[
                        "Hálux Valgus",
                        "Hálux Rígido",
                        "Esporão de Calcâneo",
                        "Pé Cavo",
                        "Pé Plano",
                        "Dedos em Garra/Martelo",
                        "Perodactilia",
                      ].map((item, index) => (
                        <Form.Check
                          inline
                          key={index}
                          type="checkbox"
                          label={item}
                          name="alteracao_osso_pe_esquerdo"
                          checked={formData.alteracao_osso_pe_esquerdo.includes(
                            item
                          )}
                          onChange={() =>
                            handleCheckboxGroupChange(
                              "alteracao_osso_pe_esquerdo",
                              item
                            )
                          }
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Button variant="primary" onClick={handleSubmit}>
                Atualizar Anamnese
              </Button>
            </Form>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default Anamnese;
