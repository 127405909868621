import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Layout from "./components/Layout";
import PatientList from "./components/PatientList";
import Anamnese from "./components/Anamnese";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* Layout com Menu Lateral para as Rotas Protegidas */}
        <Route
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path="/pacientes" element={<PatientList />} />
          {/* Para editar */}
          <Route path="/anamnese/:cpf" element={<Anamnese />} />
          <Route path="/anamnese" element={<Anamnese />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
